<template>
    <div class="page-table  mainDiv " id="affix-container">
        <el-col :span="24" class="animated fadeInUp">
            <el-button size="small" type="success" style="margin-top: 15px;"
                       @click="popTanimlama">Pop Tanımlama
            </el-button>
            <el-button size="small" type="success" style="margin-top: 15px;"
                       @click="popListDetay">Pop list
            </el-button>
            <el-button size="small" type="success" style="margin-top: 15px;"
                       @click="popDetay">Pop Detay
            </el-button>
            <el-button size="small" type="success" style="margin-top: 15px;"
                       @click="popDurumGuncelle">Pop DurumGuncelle
            </el-button>
            <el-button size="small" type="success" style="margin-top: 15px;"
                       @click="popGuncelle">Pop Güncelle
            </el-button>
        </el-col>
    </div>
</template>

<script>
    import popService from '../../../WSProvider/PopService';
    import notification from '../../../notification'

    let base64 = require('base64_js');
    let userDataBGSurec = "";
    export default {
        name: "PopDetay",
        methods: {
            popTanimlama() {
                popService.popTanimlama("Pop Baslik", "Deneme içerik", "",  "", 1, "2020-06-20", "2020-06-22", 1, 1);
            },
            popDetay() {
                popService.popDetay("PP-1001");
            },
            popDurumGuncelle() {
                popService.popDurumGuncelle("PP-1001", 0);
            },
            popGuncelle() {
                let updateList = [{
                    type: "link",
                    value: "test"
                }, {
                    type: "resim",
                    value: "yeni.jpg",
                }];
                popService.popGuncelle("PP-1001", updateList);
            },
            popListDetay() {
                popService.popListDetay(0, 5, 1);
            },
        }
    }
</script>

<style scoped>

</style>